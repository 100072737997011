var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list__content-adding list__content-adding-company ym-hide-content"},[_c('div',{staticClass:"title card"},[('CreateDealer' == this.$route.name)?_c('div',{staticClass:"title__avatar title__avatar-dealerPage"}):(
        'CreateCompany' == this.$route.name ||
          'CompanyEdit' == this.$route.name ||
          'CompanyEditId' == this.$route.name ||
          ('FromDealerCreateCompany' == this.$route.name &&
            'FromObjectCreateAccountPage' != this.$route.name &&
            'FromCompanyFromObjectCreateAccountPage' != this.$route.name &&
            'FromDealerFromObjectCreateAccountPage' != this.$route.name)
      )?_c('div',{staticClass:"title__avatar title__avatar-company"}):(
        'CreateObject' == this.$route.name ||
          'ObjectsEdit' == this.$route.name ||
          'ObjectsEditId' == this.$route.name ||
          'FromCompanyCreateObject' == this.$route.name ||
          'FromDealerCreateObject' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-objectsPage"}):(
        'CreateKindergartens' == this.$route.name ||
          'KindergartenEditId' == this.$route.name ||
          'FromDealercreateKindergartens' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-kindergardensPage"}):(
        'FromKindergartenChildrenGroupsCreate' == this.$route.name ||
          'ChildrenGroupsCreate' == this.$route.name ||
          'ChildrenGroupsEdit' == this.$route.name ||
          'ChildrenGroupsEditId' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-childrenGroupsPage"}):(
        'FromKindergartenCreateChildren' == this.$route.name ||
          'FromKindergartenFromChildrenGroupCreateChildren' ==
            this.$route.name ||
          'ChildrenEdit' == this.$route.name ||
          'ChildrenEditId' == this.$route.name ||
          'CreateChildren' == this.$route.name ||
          'FromChildrenGroupCreateChildren' == this.$route.name ||
          'FromDealerFromKindergartenCreateChildren' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-childrenPage"}):(
        'FromKindergartenPersonnelCreate' == this.$route.name ||
          'FromObjectUsersCreate' == this.$route.name ||
          'CreateStaff' == this.$route.name ||
          'PersonnelCreate' == this.$route.name ||
          'FromKindergartenFromChildrenGroupManageressCreate' ==
            this.$route.name ||
          'FromChildrenGroupManageressCreate' == this.$route.name ||
          'FromServesOrganizationCreateStaff' == this.$route.name ||
          'PersonnelEditId' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-staffPage"}):(
        'CreateController' == this.$route.name ||
          'ControllersEdit' == this.$route.name ||
          'ControllersEditId' == this.$route.name ||
          'FromKindergartenCreateController' == this.$route.name ||
          'FromDealerCreateController' == this.$route.name ||
          'FromObjectCreateController' == this.$route.name ||
          'FromCompanyCreateController' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-controllersPage"}):(
        'createFirmware' == this.$route.name ||
          'FirmwareEdit' == this.$route.name ||
          'FirmwareEditId' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-FirmwarePage"}):(
        'CreateCardPage' == this.$route.name ||
          'FromKindergartenCreateCardPage' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-cardPage"}):(
        'CreateAccountPage' == this.$route.name ||
          'FromObjectCreateAccountPage' == this.$route.name ||
          'FromCompanyFromObjectCreateAccountPage' == this.$route.name ||
          'FromDealerFromObjectCreateAccountPage' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-accountPage"}):(
        'UsersCreate' == this.$route.name ||
          'UsersEdit' == this.$route.name ||
          'FromObjectFromUserGroupUsersCreateObjects' == this.$route.name ||
          'usersObjectsEdit' == this.$route.name ||
          'UsersEditId' == this.$route.name ||
          'usersGeneralEdit' == this.$route.name ||
          'FromCompanyUsersCreate' == this.$route.name ||
          'FromKindergartenUsersCreate' == this.$route.name ||
          'FromKindergartenFromChildrenParentsCreate' == this.$route.name ||
          'FromKindergartenFromChildrenGroupFromChildrenEditParent' ==
            this.$route.name ||
          'FromKindergartenFromChildrenGroupFromChildrenParentsCreate' ==
            this.$route.name ||
          'FromChildrenParentsCreate' == this.$route.name ||
          'FromChildrenGroupFromChildrenParentsCreate' == this.$route.name ||
          'FromChildrenGroupFromChildrenEditParent' == this.$route.name ||
          'EditParent' == this.$route.name ||
          'FromKindergartenFromChildrenEditParent' == this.$route.name ||
          'EditParentId' == this.$route.name ||
          'FromChildrenEditParent' == this.$route.name ||
          'FromObjectUsersCreate' == this.$route.name ||
          'FromObjectUsersCreateObjects' == this.$route.name ||
          'FromCompanyFromObjectFromUserGroupUsersCreateObjects' ==
            this.$route.name ||
          'FromKindergartenFromUserGroupUsersCreate' == this.$route.name ||
          'FromDealerFromObjectFromUserGroupUsersCreateObjects' ==
            this.$route.name ||
          'FromCompanyFromObjectUsersCreateObjects' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-usersPage"}):(
        'FromObjectCreateUserGroup' == this.$route.name ||
          'FromKindergartenCreateUserGroup' == this.$route.name ||
          'UserGroupsEdit' == this.$route.name ||
          'FromCompanyFromObjectCreateUserGroup' == this.$route.name ||
          'UserGroupsEditId' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-userGroupsPage"}):('CreatePromocodes' == this.$route.name)?_c('div',{staticClass:"title__avatar title__avatar-promocodesPage"}):('Notification' == this.$route.name)?_c('div',{staticClass:"title__avatar title__avatar-notificationPage"}):('CreateServiceOrganization' == this.$route.name)?_c('div',{staticClass:"title__avatar title__avatar-serviceOrganizationPage"}):(
        'createAdminOppen' == this.$route.name ||
          'FromCompanyCreateAdmin' == this.$route.name ||
          'FromDealerCreateAdmin' == this.$route.name ||
          'createAdmin' == this.$route.name ||
          'FromServesOrganizationCreateAdmin' == this.$route.name ||
          'FromDealerFromCompanyCreateAdmin' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-oppenAdminsPage"}):(
        'CreateServiceTaskPage' == this.$route.name ||
          'EditServiceTaskInfo' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-ordersPage"}):(
        'ServiceGroupCreate' == this.$route.name ||
          'EditServiceGroup' == this.$route.name ||
          'FromServesOrganizationServiceGroupCreate' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-objectsGroupPage"}):(
        ('ProfileEdit' == this.$route.name &&
          this.$store.getters.getCurrentRole == 70) ||
          ('ProfileEdit' == this.$route.name &&
            this.$store.getters.getCurrentRole == 60) ||
          ('ProfileEdit' == this.$route.name &&
            this.$store.getters.getCurrentRole == 50) ||
          ('ProfileEdit' == this.$route.name &&
            this.$store.getters.getCurrentRole == 30) ||
          ('ProfileEdit' == this.$route.name &&
            this.$store.getters.getCurrentRole == 31)
      )?_c('div',{staticClass:"title__avatar title__avatar-admin"}):(
        'Split' == this.$route.name || 'DealerInfo' == this.$route.name
      )?_c('div',{staticClass:"title__avatar title__avatar-terminal"}):('ProfileEdit' == this.$route.name)?_c('div',{staticClass:"title__avatar title__avatar-profilePage"}):_c('div',{staticStyle:{"color":"red","margin-right":"20px"}},[_vm._v(" "+_vm._s(this.$route.name)+" ")]),_vm._t("title__text",function(){return [_vm._v("Компания")]}),_vm._t("title-flex-right",function(){return [_c('p',{staticClass:"title__text-info flex-right"},[_vm._v(" Просмотр аналитики рабочего времени ")]),_vm._m(0)]})],2),_c('ValidationForm',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_vm._t("default",null,{"handleSubmit":handleSubmit}),(_vm.$scopedSlots.btns)?_c('div',{staticClass:"btns card"},[_vm._t("btns",null,{"handleSubmit":handleSubmit})],2):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switcher title__switcher"},[_c('input',{staticClass:"checkbox_none",attrs:{"type":"checkbox","id":"switcher"}}),_c('label',{staticClass:"switcher__inner",attrs:{"for":"switcher"}},[_c('div',{staticClass:"switcher__point"})]),_c('p',{staticClass:"switcher__text"},[_vm._v("Выключено")])])
}]

export { render, staticRenderFns }